import React from "react";
import line from '../assets/line.svg';
import lineMobile from '../assets/line-mobile.svg';
import step1 from '../assets/step-1.svg';
import step2 from '../assets/step-2.svg';
import step3 from '../assets/step-3.svg';
import step4 from '../assets/step-4.svg';

function Section3() {
    return (
        <section className="section-3 col-12" id="section3">
            <div className="row container">
                <h3 className="h3">- Comment ça marche ?</h3>
                <h1 className="h1">
                    Comment <strong>ça fonctionne?</strong>
                </h1>

                <div className="step step-1 col-3">
                    <div className="icon">
                        <img className="" src={step1} alt="" />
                    </div>
                    <div className="text">
                        <p className="p">
                        Je reçois <br />
                        <strong>l’ordonnance</strong> 
                        </p>
                    </div>

                </div>

                <div className="step step-2 col-3">
                    <div className="icon">
                        <img className="" src={step2} alt="" />
                    </div>
                    <div className="text">
                        <p className="p">
                        Je la <br />
                        <strong>prépare</strong> 
                        </p>
                    </div>

                </div>

                <div className="step step-3 col-3">
                    <div className="icon">
                        <img className="" src={step3} alt="" />
                    </div>
                    <div className="text">
                        <p className="p">
                        Mon patient  <br />
                        <strong>est informé</strong> 
                        </p>
                    </div>

                </div>

                <div className="step step-4 col-3">
                    <div className="icon">
                        <img className="" src={step4} alt="" />
                    </div>
                    <div className="text">
                        <p className="p">
                        Il vient récupérer  <br />
                        <strong>sa commande</strong> 
                        </p>
                    </div>

                </div>
            </div>
            

            <div className="content">
                    <img className="line" src={line} alt="" />
                    <img className="line-mobile" src={lineMobile} alt="" />
            </div>
        </section>

    );
}


export default Section3;