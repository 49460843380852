import React from "react"; 
import phone from '../assets/phone-red.svg';
import mail from '../assets/mail-red.svg';
import phoneForm from '../assets/phone.svg';
import mailForm from '../assets/mail.svg';
import personForm from '../assets/person.svg';
import villeForm from '../assets/ville.svg';
import mapPinForm from '../assets/map-pin.svg';
import whiteArrow from '../assets/white-arrow.svg';


function Section9() {
    
    return(
        <section className="section-9" id="section9">
            <div className="container">
                <h1 className="h1">
                Demandez votre compte d’essai <strong>gratuitement ! </strong> 
                </h1>

                <p className="p">
                    L’équipe MonPharmacien prendra contact avec vous et répondra à toutes vos questions.
                </p>

                <div className="infos">
                    <div className="info">
                        <div className="icon">
                             <img src={phone} alt="" />
                        </div>
                        <div className="text">
                            <label>Téléphone</label>
                            <h2>+33 3 62 27 90 70</h2>
                        </div>
                    </div>

                    <div className="info">
                        <div className="icon">
                            <img src={mail} alt="" />
                        </div>
                        <div className="text">
                            <label>E-mail</label>
                            <h2>support@sobrus.com</h2>
                        </div>

                    </div>
                </div>


                <div className="form">
                    <form >
                        <div className="groupInput Item">
                            <label> Prénom & Nom </label>
                            <div className="input">
                                <div className="iconInput">
                                    <img src={personForm} alt="" />
                                </div>
                                <input type="text" name="name" placeholder="Entrez votre Nom & Prénom" />  
                            </div>
                        </div>

                        <div className="groupInput Item">
                            <label> Téléphone </label>
                            <div className="input">
                                <div className="iconInput">
                                    <img src={phoneForm} alt="" />
                                </div>
                                <input type="text" name="name" placeholder="Entrez votre téléphone" />  
                            </div>
                        </div>

                        <div className="groupInput Item">
                            <label> Adresse e-mail </label>
                            <div className="input">
                                <div className="iconInput">
                                    <img src={mailForm} alt="" />
                                </div>
                                <input type="text" name="name" placeholder="Votre adresse e-mail ici …" />  
                            </div>
                        </div>

                        <div className="groupInput Item">
                            <label> Ville de la pharmacie </label>
                            <div className="input">
                                <div className="iconInput">
                                    <img src={villeForm} alt="" />
                                </div>
                                <input type="text" name="name" placeholder="Entrez la ville de la pharmacie" />  
                            </div>
                        </div>

                        <div className="groupInput Item">
                            <label> Ville où se trouve la pharmacie </label>
                            <div className="input">
                                <div className="iconInput">
                                    <img src={mapPinForm} alt="" />
                                </div>
                                <input type="text" name="name" placeholder="Entrez la ville de la pharmacie" />  
                            </div>
                        </div>

                        <div className="groupInput Item">
                            <a className="btn btn-customize">
                                Demander un compte gratuit
                                <img src={whiteArrow} />
                            </a>
                        </div>

                        <div className="groupInput Item">
                            <input type="checkbox" id="scales" name="cgu" />
                            <label for="cgu">En soumettant ce formulaire, j’accepte que mes informations soient utilisées selon <a href="">les conditions générales d’utilisation</a> de Sobrus et sa <a href="">politique de confidentialité</a></label>
                        </div>

                        

                    </form>

                </div>
            </div>
        </section>
    );
}

export default Section9;