import logo from './logo.svg';
import './App.scss';
import Header from './components/header';
import Section1 from './components/section-1';
import Section2 from './components/section-2';
import Section3 from './components/section-3';
import Section4 from './components/section-4';
import Section5 from './components/section-5';
import Section6 from './components/section-6';
import Section7 from './components/section-7';
import Section8 from './components/section-8';
import Section9 from './components/section-9';
import Footer from './components/footer';

function App() {
  return (
    <div>
            <Header></Header>
            <Section1></Section1>
            <Section2></Section2>
            <Section4></Section4>
            <Section5></Section5>
            <Section6></Section6>
            <Section7></Section7>
            <Section8></Section8>
            <Section9></Section9>
    </div>

  );
}

export default App;
