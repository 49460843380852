import React from "react";
import blackArrow from '../assets/black-arrow.svg';

function Section4() {
    return(
        <section className="section-4 col-12" id="section4">
            <div className="container row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 conetnt-1">
                    <h1 className="h2">
                        Bénéficiez <strong>de tous les avantages du click & collect</strong> grâce au module <strong>MonPharmacien sur Sobrus Pharma !</strong> 
                    </h1>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 content-2 rightSide">
                    <a className="btn btn-custom">
                        Demandez votre compte d’essai
                        <img src={blackArrow} />
                    </a>
                </div>
            </div>

        </section>
    );

}

export default Section4;