import React from "react";
import MockUpWhite from '../assets/Macbook-white.svg';
import whiteArrow from '../assets/white-arrow.svg';

function Section6() {
    return(
        <section className="section-6 col-12" id="section6">

            <div className="container row ">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 content">
                    <h1 className="h1">
                    Gagnez en <br/> <strong>visibilité sur le web</strong>
                    </h1>
                    <p className="p">
                        grâce à notre <strong>annuaire interactif de pharmacies</strong>, MonPharmacien !
                    </p>

                    <br />
                    <a className="btn btn-customize">
                        Demander un compte gratuit
                        <img src={whiteArrow} />
                    </a>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 content">
                    <div className="ImgContent">
                        <img src={MockUpWhite} alt="" className="img-fluid"/>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default Section6;