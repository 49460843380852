import React from 'react';
import MockUpImg from '../assets/MonPharmacien-App.png';
import Bg from '../assets/bg-section-1.svg';
import whiteArrow from '../assets/white-arrow.svg';

function Section1() {
    return (
        <section className="section-1 col-12" id="section1">
            <div className="container row">
                <div className="col-xxl-6  col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <h1 className="h1">
                        Proposez <br/> le <strong>Scan & Collect</strong> à vos patients !
                    </h1>
                    <p className="p">
                        Recevez et gérez à l’avance les ordonnances de vos patients grâce au module Scan & Collect de Sobrus Pharma.
                    </p>
                    <br />
                    <a className="btn">
                        Demander un compte gratuit
                        <img src={whiteArrow} />
                    </a>
                </div>

                <div className="col-xxl-6  col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <img src={MockUpImg} alt="Test" className="img-fluid"/>
                </div>
            </div>
        </section>
    );
}

export default Section1;