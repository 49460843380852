import React from "react";
import Section3 from "./section-3";

/* Icons */ 
import pageWeb from '../assets/pageWeb.svg';
import worldGrid from '../assets/world-grid.svg';
import relationClient from '../assets/relationClient.svg';

function Section8() {
    return(
        <section className="section-8 col-12" id="section8">
            <div className="container row">
                <h3 className="h3">- Avantages</h3>
                <h1 className="h1">
                    Que m’apporte <strong>le module visibilité ?</strong>
                </h1>

                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 content">
                    <div className="icon">
                    <img src={pageWeb} alt="" />
                    </div>
                    <div className="text">
                        <p className="p">
                            Une <strong>page professionnelle</strong> pour votre pharmacie
                        </p>
                    </div>
                </div>

                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 content">
                    <div className="icon">
                    <img src={worldGrid} alt="" />
                    </div>
                    <div className="text">
                        <p className="p">
                            Une <strong>meilleure présence</strong> de votre pharmacie sur internet
                        </p>
                    </div>
                </div>

                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 content">
                    <div className="icon">
                    <img src={relationClient} alt="" />
                    </div>
                    <div className="text">
                        <p className="p">
                            Un <strong>renforcement de la relation</strong> pharmacien-patient
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default Section8;