import React, { Component, useState } from 'react';
import logo from '../assets/monpharmacien.svg';
import whiteArrow from '../assets/white-arrow.svg';
import menuMobile from '../assets/menu-icon-white.svg';
import menuClose from '../assets/menu-icon-close.svg';

import { Link, animateScroll as scroll } from "react-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Header extends React.Component {
    state = {
        isBoxVisible:false
      };
    
    toggleBox = () => {
        this.setState(prevState => ({ isBoxVisible: !prevState.isBoxVisible }));
      };

    render() {
        const { isBoxVisible } = this.state;
        return (
            <header className="header">
                <div className="container">
                    <img className="logo" src={logo} alt="MonPharmacien" height="45"/>
                    <nav>
                        <a href="">
                            <Link
                            activeClass="is-active"
                            to="section1"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={1000}
                            >
                                <span>
                                </span>
                                Accueil
                            </Link>
                        </a>
                        <a href="">
                        <Link
                            activeClass="is-active"
                            to="section2"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={1000}
                            >
                                <span></span>
                                Présentation
                            </Link>
                            
                        </a>
                        <a href="">
                            <Link
                            activeClass="is-active"
                            to="section3"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={1000}
                            > 
                                 <span></span>
                                Comment ça marche ?
                            </Link>
                            </a>
                        <a href="">
                            <Link
                            activeClass="is-active"
                            to="section5"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={1000}
                            >
                                <span></span>
                                Avantages
                            </Link>
                        </a>
                        <a className="btn-outline"> Se connecter</a>
                        
                        <a className="btn">
                            Demander un compte gratuit
                            <img src={whiteArrow} />
                        </a>
                    </nav>

                    
                    <div className="btnMenu" onClick={this.toggleBox}>
                        <img className="" src={menuMobile} alt=""/>
                    </div>
                    <div className={`${isBoxVisible ? "menuModal" : "hidden"}`}>
                        <ul>
                            <li>
                                <Link
                                activeClass="is-active"
                                to="section1"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={1000}
                                onClick={this.toggleBox}
                                >
                                    <span>
                                    </span>
                                    Accueil
                                </Link>
                            </li>
                            <li>
                                <Link
                                activeClass="is-active"
                                to="section2"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={1000}
                                onClick={this.toggleBox}
                                >
                                    <span>
                                    </span>
                                    Présentation
                                </Link>
                            </li>
    
                            <li>
                                <Link
                                activeClass="is-active"
                                to="section3"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={1000}
                                onClick={this.toggleBox}
                                >
                                    <span>
                                    </span>
                                    Comment ça marche ?
                                </Link>
                            </li>
                            <li>
                                <Link
                                activeClass="is-active"
                                to="section4"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={1000}
                                onClick={this.toggleBox}
                                >
                                    <span>
                                    </span>
                                    Avantages
                                </Link>
                            </li>


    
                            <li className="btnClose" onClick={this.toggleBox}>
                                <img className="" src={menuClose} alt=""/>
                            </li>
                        </ul>
    
    
                    </div>
                </div>
            </header>
        );
    }

}

export default Header;